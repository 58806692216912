<template>
  <div class="tw-relative">
    <label :for="label"></label>
    <input
      type="text"
      class="tw-w-full tw-bg-gray-lightest tw-rounded tw-border-white tw-p-4 focus:tw-border-primary"
      :value="search"
      @input="updateSearch"
      :placeholder="placeHolder"
    />
    <img
      src="@/assets/img/search.svg"
      class="tw-absolute tw-top-3.5 tw-right-2"
      alt="search"
    />
  </div>
</template>

<script>
export default {
  name: "EuropeBankList",

  emits: ["set"],

  props: {
    placeHolder: { type: String, default: "" },
    label: { type: String, default: "" },
    search: { type: String, default: "" },
  },

  methods: {
    updateSearch(event) {
      this.$emit("set", event.target.value);
    },
  },
};
</script>

<style lang="scss" scoped></style>
