<template>
  <div id="manual-add-bank">
    <div class="tw-mt-8">
      <h3 class="tw-text-xl tw-font-semibold tw-mb-2">Enter Account Details</h3>
      <p class="tw-text-black-light">
        GetEquity lets you link a Nigerian bank account or an international bank
        account.
      </p>
      <form @submit.prevent="addBank" class="tw-mt-6">
        <div class="tw-mb-8">
          <TextInput
            placeHolder="Account Name"
            :text="payload.account_name"
            type="text"
            name="accountName"
            id="accountName"
            label="Account Name"
            :showLabel="true"
            @set="setAccountName"
            @valid="accountNameValid"
          />
        </div>
        <div class="tw-mb-8">
          <TextInput
            placeHolder="Account Number"
            :text="payload.account_number"
            type="text"
            name="accountNumber"
            id="accountNumber"
            label="Account Number"
            :showLabel="true"
            @set="setAccountNumber"
            @valid="accountNumberValid"
          />
        </div>
        <div class="tw-mb-8">
          <SelectInput
            label="Currency"
            defaltOption="Select currency"
            name="currency"
            id="currency"
            :showLabel="true"
            :list="currencyList"
            @set="setCurrency"
          />
        </div>
        <div class="tw-mb-8">
          <TextInput
            placeHolder="Bank Name"
            :text="payload.bank_name"
            type="text"
            name="bankName"
            id="bankName"
            label="Bank Name"
            :showLabel="true"
            @set="setBankName"
            @valid="bankNameValid"
          />
        </div>
        <div class="tw-mb-8">
          <TextInput
            placeHolder="Swift Code"
            :text="payload.swift_code"
            :required="payload.currency !== 'NGN'"
            type="text"
            name="swiftCode"
            id="swiftCode"
            label="Swift Code"
            :showLabel="true"
            @set="setSwiftCode"
            @valid="swiftCodeValid"
          />
        </div>
        <div class="tw-mb-8">
          <TextInput
            placeHolder="Sort Code"
            :text="payload.sort_code"
            type="text"
            name="sortCode"
            :required="payload.currency !== 'NGN'"
            id="sortCode"
            label="Sort Code"
            :showLabel="true"
            @set="setSortCode"
            @valid="sortCodeValid"
          />
        </div>
        <div class="tw-mb-8">
          <TextInput
            placeHolder="Bank Address"
            :text="payload.bank_address"
            type="text"
            name="address"
            id="address"
            label="Address"
            :showLabel="true"
            @set="setAddress"
            @valid="bankAddressValid"
          />
        </div>
        <Btn class="tw-mt-12" title="Add Bank" :loading="loading" />
      </form>
    </div>
  </div>
</template>

<script>
  import Btn from "@/components/general/BtnComponent.vue";
import SelectInput from "@/components/general/SelectInput.vue";
import TextInput from "@/components/general/TextInput";
import { mapActions } from "vuex";

  export default {
    name: "ManualAddBank",

    emits: ["added"],

    components: {
      TextInput,
      SelectInput,
      Btn,
    },

    data() {
      return {
        loading: false,
        validInput: {
          account_name: false,
          account_number: false,
          bank_name: false,
          swift_code: false,
          sort_code: false,
          bank_address: false,
        },
        payload: {
          account_name: "",
          account_number: "",
          bank_name: "",
          currency: "",
          swift_code: "",
          sort_code: "",
          bank_address: "",
        },
        currencyList: [
          "🇺🇸 US Dollars (USD)",
          "🇳🇬 Nigerian Naira (NGN)",
          // "🇬🇧 Pounds (GBP)",
          // "🇪🇺 Euro (EUR)",
          "🇰🇪 Kenyan Shilling (KES)",
          // "🇹🇿 Tanzanian Shilling (TZS)",
        ],
      };
    },

    methods: {
      ...mapActions("Account", ["addBankAccount", "fetchBankAccounts"]),
      ...mapActions(["showToast"]),

      addBank() {
        setTimeout(async () => {
          if (
            !this.validInput.account_name ||
            !this.validInput.account_number ||
            !this.validInput.bank_name ||
            !this.validInput.swift_code ||
            !this.validInput.sort_code ||
            !this.validInput.bank_address
          ) {
            return this.showToast({
              description: "Please fill all input field correctly",
              display: true,
              type: "error",
            });
          }
          this.loading = !this.loading;
          if (this.payload.currency === "NGN") {
            delete this.payload.swift_code;
            delete this.payload.sort_code;
          }
          try {
            const response = await this.addBankAccount(this.payload);

            const { message } = response.data;
            this.showToast({
              description: message,
              display: true,
              type: "success",
            });
            await this.fetchBankAccounts();
            this.$emit("added");
            this.loading = !this.loading;
          } catch (error) {
            this.loading = !this.loading;
          }
        }, 500);
      },

      setAccountName(text) {
        this.payload.account_name = text;
      },

      setAccountNumber(text) {
        this.payload.account_number = text;
      },

      setCurrency(option) {
        if (!option) return;
        let abb = option.split("(")[1].split(")")[0];
        this.payload.currency = abb;
      },

      setBankName(text) {
        this.payload.bank_name = text;
      },

      setSwiftCode(text) {
        this.payload.swift_code = text;
      },

      setSortCode(text) {
        this.payload.sort_code = text;
      },

      setAddress(text) {
        this.payload.bank_address = text;
      },

      accountNameValid(valid) {
        this.validInput.account_name = valid;
      },

      accountNumberValid(valid) {
        this.validInput.account_number = valid;
      },

      bankNameValid(valid) {
        this.validInput.bank_name = valid;
      },

      swiftCodeValid(valid) {
        this.validInput.swift_code = valid;
      },

      sortCodeValid(valid) {
        this.validInput.sort_code = valid;
      },

      bankAddressValid(valid) {
        this.validInput.bank_address = valid;
      },
    },
  };
</script>

<style lang="scss" scoped></style>
