<template>
	<div id="select-country" class="tw-mt-8">
		<form @submit.prevent="handleBankType">
			<label for="select country" class="tw-text-[#4C5B90] tw-text-sm tw-mb-2"
        >Currency</label
      >
      <select
        name="currency"
        id="currency"
        class="tw-w-full tw-flex tw-flex-col tw-rounded tw-p-4"
        v-model="currency"
        @input="setCountry"
        required
      >
        <option value="" disabled>Select Country</option>
        <option
          :value="currency"
          v-for="(currency, index) in currencies"
          :key="index"
        >
          {{ currency }}
        </option>
      </select>
      <CustomButton
        @click="handleBankType"
        class="tw-mt-8"
        title="Continue"
        :loading="loading"
      />
			<div v-if="linkToken">
				<PlaidLink
					clientName="GetEquity"
					:env="plaidEnv"
          :onLoad='onPlaidLoad'
					:link_token="linkToken"
					:products="['auth', 'transactions']"
					:onSuccess="onPlaidSuccess"
					:onExit="onPlaidExit"
					:onEvent="onPlaidEvent"
				>
					<div class="tw-mt-3" v-if="showPlaidButton">
						<p
							class="tw-text-[medium] tw-leading-[125%] tw-text-[#234160] tw-inline-flex"
						>
							If you're not redirected to Plaid,
							<span ref="plaidLink" class="tw-text-primary tw-cursor-pointer"
								>click to proceed</span
							>
						</p>
					</div>
				</PlaidLink>
			</div>
		</form>
		<div v-if="currency === 'European Countries'" class="tw-mt-8">
			<label for="select country" class="tw-text-sm">
				Select European Country
			</label>
			<select
				name="syndicateType"
				id="syndicateType"
				class="tw-w-full tw-flex tw-flex-col tw-rounded tw-p-4"
				v-model="euroCountry"
				@input="setEuroCountry"
				required
			>
				<option value="" disabled>Select Country</option>
				<option
					:value="country.iso2"
					v-for="(country, index) in europeanCountries.countries"
					:key="index"
				>
					{{ country.name }}
				</option>
			</select>
		</div>
		<div v-if="euroCountry !== ''" class="tw-mb-32 tw-mt-10">
			<label for="select country" class="tw-text-sm">Select Your Bank</label>
			<div class="tw-relative tw-border tw-border-gray tw-rounded tw-px-4">
				<EuropeBankList
					class="tw-mt-8"
					placeHolder="Search"
					label="search"
					:search="bank"
					@set="setSearch"
				/>
				<ul class="tw-h-72 tw-overflow-auto">
					<li
						v-for="(bank, index) in filteredBanks"
						:key="index"
						class="tw-flex tw-items-center tw-text-gray tw-text-lg tw-cursor-pointer tw-px-6 tw-my-4"
						@click="fetchNordigenLink(bank.id, bank.name)"
					>
						<img :src="bank.logo" class="tw-w-10 tw-mr-4" alt="bank logo" />
						{{ bank.name }}
					</li>
				</ul>
				<SmallLoader
					v-if="euroCountry !== '' && !euroBankLoading"
					class="tw-absolute tw-top-36 tw-left-40"
				/>
				<EmptyState
					v-if="filteredBanks.length === 0 && euroBankLoading"
					class="tw-absolute tw-top-36 tw-left-32"
				>
					<template v-slot:message>
						<p class="message tw-text-center tw-text-gray tw-my-3">
							No banks found.
						</p>
					</template>
				</EmptyState>
			</div>
		</div>
		<ManualAddBank v-if="showManualForm" @added="$emit('bank-connected')" />
	</div>
</template>

<script>
import ManualAddBank from "@/components/account/ManualAddBank";
import CustomButton from "@/components/general/BtnComponent";
import EmptyState from "@/components/notification/EmptyState";
import SmallLoader from "@/components/notification/SmallLoader";
import EuropeBankList from "@/components/wallet/EuropeBankList";
import bankApi from "@/utils/addBankApi";
import europeanCountries from "@/utils/json/europeanCountries.json";
import { setItem } from "@/utils/storage.js";
import MonoConnect from "@mono.co/connect.js";
import PlaidLink from "vue-plaid-link2";
import { mapActions, mapGetters } from "vuex";

export default {
	name: "SelectCountryForm",

	components: {
		EuropeBankList,
		SmallLoader,
		EmptyState,
		PlaidLink,
		ManualAddBank,
		CustomButton,
	},

	data() {
		return {
			europeanCountries,
			currency: "",
			euroCountry: "",
			allBanks: [],
			bank: "",
			plaidEnv: process.env.VUE_APP_PLAID_ENV,
			currencies: [
				"Nigerian Naira (NGN)",
				"Kenyan Shillings (KES)",
				"United States Dollars (USD)",
				// "Ghana",
				// "South Africa",
				// "European Countries",
				// "North America",
				"Add Manually",
			],
			monoConnect: null,
			euroBankLoading: false,
			linkToken: "",
			plaidSuccess: false,
			showPlaidButton: false,
			showManualForm: false,
		};
	},

	watch: {
		linkToken(val) {
			if (val.length > 0) {
				this.setLoading(true);
				this.showPlaidButton = true;
				setTimeout(() => {
					this.setLoading(false);
					this.$refs.plaidLink.click();
				}, 1000);
			}
		},
		currency(val) {
			if (val !== "Add Manually") {
				this.showManualForm = false;
			}
		},
	},
	computed: {
		...mapGetters(["loading"]),
		filteredBanks() {
			if (!this.bank) {
				return this.allBanks;
			}
			return this.allBanks.filter((bank) =>
				bank.name.toLowerCase().includes(this.bank.toLowerCase())
			);
		},
	},
	emits: ["bank-connected"],

	methods: {
		...mapActions("Account", ["fetchBankAccounts"]),
		...mapActions(["showToast", "setLoading"]),

		setSearch(text) {
			this.bank = text;
		},

		setCountry(event) {
			this.euroCountry = "";
			this.currency = event.target.value;
			this.handleBankType();
		},
		async handleBankType() {
			switch (this.currency) {
				case "Nigerian Naira (NGN)":
					this.monoConnect = await this.setupMono("ng");
					this.monoConnect.open();
					break;
				case "Kenyan Shillings (KES)":
					this.monoConnect = await this.setupMono("ke");
					this.monoConnect.open();
					break;
				case "Ghana":
					this.monoConnect = await this.setupMono("gh");
					this.monoConnect.open();
					break;
				case "South Africa":
					this.connectForeignAccount("stitch");
					break;
				case "United States Dollars (USD)":
					this.connectForeignAccount("plaid");
					break;
				case "Add Manually":
					this.showManualForm = true;
					break;
			}
		},
		async fetchNordigenLink(id, name) {
			this.setLoading(true);
			let redirectUri = `${window.location.origin}${window.location.pathname}`;
			try {
				const response = await bankApi.initiateBankConnection("nordigen", {
					id,
					name,
					redirectUri,
				});
				if (response.status === 201 || response.status === 200) {
					window.location = response.data.data.link;
				} else {
					this.setLoading(false);
					this.showToast({
						description: response.data.message,
						display: true,
						type: "error",
					});
				}
			} catch (error) {
				this.setLoading(false);
				this.showToast({
					description: "We could not initiate this integration.",
					display: true,
					type: "error",
				});
			}
		},

		async setEuroCountry(event) {
			this.euroCountry = event.target.value;
			this.euroBankLoading = false;
			try {
				const response = await bankApi.fetchInstitutions(this.euroCountry);
				if (response.status === 201 || response.status === 200) {
					this.allBanks = response.data.data;
					this.euroBankLoading = true;
				} else {
					this.showToast({
						description: response.data.message,
						display: true,
						type: "error",
					});
				}
			} catch (error) {
				this.showToast({
					description: "Error fetching institutions",
					display: true,
					type: "error",
				});
			}
		},

		async connectForeignAccount(type) {
			this.setLoading(true);
			let request;
			if (type === "stitch") {
				const redirectUri = `${window.location.origin}${window.location.pathname}`;
				request = bankApi.initiateBankConnection("stitch", { redirectUri });
			} else if (type === "plaid") {
				request = bankApi.initiateBankConnection(type);
			}
			try {
				const response = await request;
				if (response.status === 201 || response.status === 200) {
					const handler =
						{
							stitch: () => this.handleStitchInitiationResponse(response),
							plaid: () => this.handlePlaidInitiationResponse(response),
						}[type] || (() => {});
					handler();
				} else {
					this.setLoading(false);
					this.currency = "";
					this.showToast({
						description: response.data.message,
						display: true,
						type: "error",
					});
				}
			} catch (error) {
				this.setLoading(false);
				this.currency = "";
				this.showToast({
					description: "We could not initiate this bank connection.",
					display: true,
					type: "error",
				});
			}
		},

		setupMono(country) {
			// eslint-disable-next-line prefer-const
			let self = this;
			let key = null;
			switch (country) {
				case "ng":
					key = process.env.VUE_APP_MONO_BUSINESS;
					break;
				case "ke":
					key = process.env.VUE_APP_MONO_KE_PERSONAL;
					break;
				case "gh":
					key = process.env.VUE_APP_MONO_GH_BUSINESS;
					break;
			}
			const monoInstance = new MonoConnect({
				onSuccess: function(code) {
					self.setLoading(true);
					code.country = country;
					self.generalSuccessHandler("mono", code);
				},
				key,
			});
			monoInstance.setup();
			return monoInstance;
		},

		handlePlaidInitiationResponse(response) {
			this.plaidSuccess = false;
			this.linkToken = response.data.data.link;
		},
		handleStitchInitiationResponse(response) {
			const { link, state, verifier } = response.data.data;
			setItem(state, verifier);
			window.location = link;
		},
		/**
		 * @param {string} type
		 * @param {object} data
		 */
		async generalSuccessHandler(type, data) {
			this.setLoading(true);
			try {
				const response = await bankApi.finalizeBankConnection(type, data);
				if (response.status === 201 || response.status === 200) {
					this.showToast({
						description: "Bank connection was successful",
						display: true,
						type: "success",
					});
					await this.fetchBankAccounts();
					this.$emit("bank-connected", "selectCountryForm");
					this.setLoading(false);
				} else {
					this.setLoading(false);
					this.showToast({
						description: response.response.data.message,
						display: true,
						type: "error",
					});
				}
			} catch (error) {
				this.currency = "";
				this.showToast({
					description: error.data.message,
					display: true,
					type: "error",
				});
				this.setLoading(false);
			}
		},
		onPlaidLoad() {
			// console.log("Plaid Loaded");
		},
		onPlaidSuccess(publicToken) {
			if (!this.plaidSuccess) {
				this.linkToken = "";
				this.generalSuccessHandler("plaid", { code: publicToken });
				this.plaidSuccess = true;
			}
		},
		onPlaidExit() {
			this.linkToken = "";
		},
		onPlaidEvent(e) {
			if (e === "HANDOFF") {
				this.$emit("bank-connected", "selectCountryForm");
				this.fetchBankAccounts();
			}
		},
	},
};
</script>

<style lang="scss" scoped></style>
